<template>
    <div>
        <el-button type="text" @click="dialogVisible = true">
            《火币充值用户条例》
        </el-button>
        <RocketHeaderDilaog width="500px" :visible.sync="dialogVisible">
            <div class="header-title">《火币充值用户条例》</div>
            <div style="padding: 180px 20px 20px">
                <div class="line">欢迎使用放舱网！</div>
                <div class="line text">
                    为使用放舱网的服务，您应当阅读并遵守
                    <span>《火币充值用户条例》</span>
                    （以下简称“本协议”）。请您务必审慎阅读、充分理解各条款内容。
                    除非您已充分阅读、完全理解并接受本协议所有条款，否则您无权使用放舱网服务。
                </div>
                <div class="line text">
                    您使用放舱网服务，或者以其他任何明示或者默示方式表示接受本协议的，均视为您已阅读并同意签署本协议。本协议即在您与放舱网
                    之间产生法律效力，成为对双方均具有约束力的法律文件。
                </div>
                <div class="line text">
                    放舱网开发了一套系统，命名为<span>火币</span>，于平台内使用。
                    您可使用现金通过在线支付为您的账号充入火币。火币可用于使用放舱网提供的数据服务。一旦成功充值，
                    火币将不得回兑成现金或其他形式予以退还，除非我方另行明确同意或者适用的法律法规另有明确要求。
                    您确认并同意火币仅可用来支付放舱网提供的数据服务，且已充入账号的火币不得与其他账号进行交易。
                    如果您违反本条规定进行交易，放舱网有权采取处理措施。您确认并同意放舱网有权根据业务需要，
                    独立决定开发、激活或停用火币可适用的某些功能和服务。一旦服务使用完成，
                    您将不得撤销或要求将已提供的服务回兑成相应的现金或火币。
                </div>
                <DialogFooter
                    confirmText="同意"
                    cancelText="关闭"
                    @confirm="agreeClick"
                    @cancel="dialogVisible = false"
                />
            </div>
        </RocketHeaderDilaog>
    </div>
</template>

<script>
import DialogFooter from "@/components/layout/DialogFooter.vue";
import RocketHeaderDilaog from "@/components/layout/RocketHeaderDilaog.vue";
export default {
    props: {},
    components: { DialogFooter, RocketHeaderDilaog },
    data() {
        return {
            dialogVisible: false,
            checked: false,
            dialogHeader: require("@/assets/img/dialogHeader.png"),
        };
    },
    methods: {
        agreeClick() {
            this.dialogVisible = false;
            this.checked = true;
            this.$emit("dialogChecked", this.checked);
            console.log(this.checked);
        },
        //   handleClose(done) {
        //     this.$confirm('确认关闭？')
        //       .then(_ => {
        //         done();
        //       })
        //       .catch(_ => {});
        //   }
    },
};
</script>
<style scoped lang="stylus">
.header-title{
    text-align:center;
    font-size: 32px;
    position:absolute;
    top: 60px;
    right:20px;
    color: #fff;
    font-weight: bold;
}
.bold {
    font-weight: bold;
}

.text{
    text-indent: 2em;
}

.line{
    line-height: 24px;
    margin: 4px 0;
    span{
        margin: 0 4px;
        color: #b87100;
    }
}
</style>
